<template lang="pug">
  .advisory-list
    .top
      .top-l
        MTitleI(title="保险家园列表")
      .top-r.cur-btn(@click="onAdd" v-if="userInfo.readyOnly == 1") 新增
    GeminiScrollbar(class="my-scroll-w" autoshow style="height:92%")  
      MyListItem(v-for='item in informationList' :item="item.Information" :fileUrl="item.informationFile" :readonly="userInfo.readyOnly == 1" @onChangeItem="onChangeItem")
      div(style="height:10px;")
    MyModal1(:modalShow="dialogVisible" marginTop="10vh" :title="modalTitle" :modal="true" @onClose="onClose")
      .form-box
        el-form(ref="form" :hide-required-asterisk="true" :rules="rules" :model="form" label-width="7.5rem")
          el-form-item(label="标题：" class="my-form-1" prop="title")
            Box3
              el-input(v-model="form.title" :maxlength="30" placeholder="请输入标题，30个字以内")
          el-form-item(label="内容简介：" class="my-form-1" prop="introduction")
            Box3
              el-input(v-model="form.introduction" placeholder="请输入内容简介")
          el-form-item(label="内容：" class="my-form-3" prop="content")
            Tinymce(ref="editor" :contents="form.content")  
          el-form-item(label="上传附件：" class="my-form-2")
            el-upload(
              :action="uploadUrl"
              ref="uploader"
              class="my-uploader-home"
              :limit="4"
              name="files"
              :multiple="true"
              :auto-upload="true"
              :file-list="fileList"
              :on-exceed="onExceed"
              :on-success="onSuccess"
              :on-error="onError"
              :on-progress="onProgress"
              :on-change="onChangeFile"
              :on-remove="handleRemove"
              :before-upload="onBefroeUpload"
            )
              <el-button size="small" type="primary" class="my-uploader-home-btn">点击上传</el-button>
              <div slot="tip" class="el-upload__tip upload-place">支持上传多个文件或视频</div>
      .bottom-btn-box
        .btn-item.btn-item1(@click="onBack") 返回
        .btn-item.cur-btn(@click="onRelease") 发布      
</template>

<script>
import Tinymce from "../../components/Tinymce.vue";
import MyModal1 from "../../components/MyModal1.vue";
import MyListItem from "../../components/MyListItem.vue";
import Box3 from "../../components/Box3.vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false, //modal 显示隐藏
      curItemId: "", //点击编辑按钮时，当前保险列表的 id
      fileList: [], //附件上传列表，回显列表
      modalIdx: 0, //用于动态显示 modal title
      uids: [], //附件上传成功的 id
      urlList: "", //附件回显列表
      //表单提交参数
      form: {
        title: "",
        content: "",
        introduction: "",
      },
      //表单验证规则
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { maxlength: 30, message: "最多可输入30个字", trigger: "change" },
        ],
        introduction: [
          { required: true, message: "请输入内容简介", trigger: "blur" },
        ],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
    };
  },
  components: {
    MyModal1,
    MyListItem,
    Box3,
    Tinymce,
  },
  watch: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.app.userInfo,
      pagation: (state) => state.insuranceHome.pagation, //分页参数
      informationList: (state) => state.insuranceHome.informationList, //保险列表
    }),
    uploadUrl() {
      //根据环境动态判断 附件上传地址
      return process.env.NODE_ENV === "production"
        ? window.g.UPLOADER_URL
        : "/api/common/uploadFile";
    },
    modalTitle() {
      //modal title
      return this.modalIdx === 0 ? "新增信息" : "编辑信息";
    },
  },
  created() {
    this.getinformationListAction();
  },
  methods: {
    ...mapActions([
      "getInformationDetailAction",
      "getinformationListAction",
      "deleteinformationAction",
      "saveorupdateinfoAction",
    ]),
    openConfim(sucCallback) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          if (sucCallback && typeof sucCallback === "function") {
            sucCallback();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //超出文件上传个数
    onExceed() {
      this.$message({
        message: "一次最多上传4个文件！",
        type: "warning",
      });
    },
    //文件上传之前
    onBefroeUpload(file) {
    },
    onChangeFile(file, fileList) {
      const { size, uid } = file;
      const isLt10M = size / 1024 / 1024 < 10;
      let uids = [];
      if (!isLt10M) {
        uids.push(uid);
        this.$message({
          message: "上传文件大小不能超过 10MB!",
          type: "warning",
        });
      }
      this.uids = uids;
    },
    //文件上传时
    onProgress(event, file, fileList) {
      if (fileList.length === 0) {
        this.$refs.uploader.abort();
      }
    },
    //文件上传成功
    onSuccess(response, file, fileList) {
        this.fileList = fileList.map(item => {
            return {
                ...item,
                fileUrl: item.response ? item.response.resultObject : item.fileUrl
            }
        });
    },
    //文件上传失败
    onError(err, file, fileList) {
      this.$message({
        type: "warning",
        message: "上传失败!",
      });
    },
    //list delete edit 操作事件
    onChangeItem(idx, item, file) {
      // idx:0 编辑 1:删除 2:标题
      let id = item.informationId;
      let _this = this;
      if (idx === 2) {
        this.getInformationDetailAction({ id });
        this.$router.push({ path: "/insurance_home/detail" });
      } else if (idx === 0) {
        let json = {
          title: item.title,
          content: item.content,
          introduction: item.introduction,
        };
        this.curItemId = item.informationId;
        let urlList = file && file.length ? file : [];
        for (let i = 0; i < urlList.length; i++) {
          urlList[i].url = urlList[i].fileUrl;
          urlList[i].name = "附件" + (i + 1);
        }
        this.fileList = JSON.parse(JSON.stringify(urlList));
        this.form = json;
        this.modalIdx = 1;
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.editor.setContent(item.content);
        });
      } else {
        this.openConfim(async () => {
          let res = await this.deleteinformationAction({ id });
          if (res.result) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getinformationListAction();
        });
      }
    },
    //返回事件
    onBack() {
      this.clearForm();
      this.dialogVisible = false;
    },
    //清空表单
    clearForm() {
      this.form = {
        title: "",
        content: "",
        introduction: "",
      };
      this.$refs["form"].resetFields();
      this.fileList = [];
      this.$refs.editor.setContent();
    },
    //发布事件
    onRelease() {
      this.urlList = '';
      this.fileList.forEach((item, index) => {
          this.urlList += `${item.fileUrl}${
              index !== this.fileList.length - 1 ? "," : ""
          }`;
      });
      let editContent = this.$refs.editor.content;
      this.form.content = editContent;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            informationId: this.curItemId,
            title: this.form.title,
            introduction: this.form.introduction,
            content: editContent || this.form.content,
            informationType: 2,
            urlList: this.urlList,
          };
          let urlStr = "";
          let res = await this.saveorupdateinfoAction(params);
          if (res.result) {
            this.$message({
              message: "保存成功！",
              type: "success",
            });
            this.clearForm();
            this.dialogVisible = false;
            this.getinformationListAction();
          } else {
          }
        }
      });
    },
    //鼠标单击的事件
    onClick(e, editor) {
    },
    //modal 关闭时的回调
    onClose() {
      this.clearForm();
      this.dialogVisible = false;
    },
    //新增
    onAdd() {
      this.dialogVisible = true;
      this.curItemId = "";
      this.modalIdx = 0;
    },
    //附件移除时的回调
    handleRemove(file, fileList) {
      this.fileList = JSON.parse(JSON.stringify(fileList));
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .my-modal-body {
  padding-top: 30px;
}
.my-uploader {
  .df;
}
.my-uploader-home-btn {
  background: url("../../assets/image/button2.png");
  background-size: 100% 100%;
  border-radius: 0.125rem;
  font-size: 0.875rem;
  color: #fff;
  width: 5rem;
  height: 1.875rem;
  text-align: center;
  line-height: 1.875rem;
  cursor: pointer;
  padding: 0;
  border: 0;
}
.form-box {
  padding-right: 10px;
  /deep/ .el-form-item__label {
    font-size: 14px;
    color: #ffffff;
  }
  .upload-place {
    color: #5a86d3;
    font-size: 14px;
    line-height: 20px;
  }
  .my-form-3 {
    /deep/ .mce-edit-area,
    /deep/ .mce-panel,
    /deep/ .mce-tinymce {
      background: transparent;
      border: 1px solid #2b58a6;
    }
    /deep/ .mce-menubar,
    /deep/ .mce-btn {
      background: transparent;
      border: none;
    }
    /deep/ .mce-top-part {
      * {
        color: #5b9ad4;
      }
    }
    /deep/ .mce-statusbar {
      display: none;
    }
    /deep/ .mce-toolbar-grp {
      border: none;
    }
    /deep/ .mce-toolbar {
      border-top: 1px solid #2b58a6;
    }
  }
}
.advisory-list {
  width: 68%;
  margin: 0 auto;
  background: rgba(28, 42, 117, 0.4);
  min-height: 200px;
  padding: 20px;
  color: #fff;
  overflow: hidden;
  height: 84vh;
  .delete,
  .edit {
    height: 16px;
    width: 16px;
    background-size: 100% 100%;
  }
  .edit {
    background: url("../../assets/icon/edit-icon.svg");
    background-color: #38bbff;
  }
  .delete {
    background: url("../../assets/icon/delete-icon.svg");
    background-color: #eb604b;
  }
  .top {
    .df;
    .dfjs;
    .dfac;
    padding-bottom: 16px;
  }
}
.bottom-btn-box {
  .df;
  .jcc;
  .btn-item {
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin: 10px 20px;
    cursor: pointer;
  }
  .btn-item1 {
    color: #318ef0;
    border: 1px solid #318ef0;
    border-radius: 2px;
  }
  .btn-item1:hover {
    color: #318ef0;
    border: 0.0625rem solid #318ef0;
    background: rgba(65, 105, 225, 0.3);
  }
}
/deep/ .my-scroll-w {
  .gm-scroll-view::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .list-item {
    width: calc(100% - 25px);
  }
}
</style>
